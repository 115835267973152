/***
 *
 *   SIGN UP STEP 3
 *   Verify Your Business
 *
 **********/

import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Animate,
    Row,
    Card,
    AuthContext,
    Button,
    TextInput,
    PhoneInput,
    useAPI,
    useView,
    CircleSpinner,
    ModalContainer,
    useSocket
} from 'components/lib';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import VerificationInput from "react-verification-input";

export default function VerifyBusiness(props) {
    const view = useView()
    const context = useContext(AuthContext);
    const varificationInputRef = useRef(null)

    const { data, loading } = useAPI('/api/account/business');

    const [calling, setCalling] = useState(false)
    const [seconds, setSeconds] = useState(30)

    const [verificationCode, setVerificationCode] = useState('')

    useEffect(() => {
        let timeout = null
        if (calling) {
            timeout = setTimeout(() => {
                setCalling(false)
                setSeconds(30)
            }, 15000)
        }
        return () => timeout && clearTimeout(timeout)

    }, [calling])

    useEffect(() => {
        let interval = null
        if (calling) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1)
            }, 1000)
        }
        return () => interval && clearInterval(interval)
    }, [calling])

    const callMutation = useMutation({
        mutationFn: () => axios.post('/api/account/call_business_number')
    })

    const verificationMutation = useMutation({
        mutationFn: (value) => axios.post('/api/account/verification_code', { verification_code: value })
    })

    const handleCall = async () => {
        if (calling) return

        try {
            const res = await callMutation.mutateAsync()
            setCalling(true)
            varificationInputRef.current?.focus()
            view.notification.show(res.data.message, 'success', true)
        } catch (e) {
            view.handleError(e)
        }
    }

    const handleVerificationCode = async (value) => {
        if (value.length > 6) return
        setVerificationCode(value)
    }

    useEffect(() => {
        if (verificationCode.length === 6) {
            verificationMutation.mutateAsync(verificationCode)
                .then(res => {
                    if (res?.data?.business_verified) {
                        view.notification.show('Your business number has been verified.', 'success')

                        window.gtag('event', 'conversion', {
                            'send_to': `${process.env.REACT_APP_AW_CONVERSION_ID}/${process.env.REACT_APP_AW_SIGNUP_EVENT_ID}`,
                            'value': 1.0,
                            'currency': 'USD'
                        });

                        return context.signin(res);
                    }
                    view.notification.show('Invalid Verification Code', 'error')
                })
                .catch(e => view.handleError(e))
        }
    }, [verificationCode])

    const attRef = useRef(null)
    const [att, setAtt] = useState(null)
    const [modal, setModal] = useState(false)
    const manualVMutation = useMutation({
        mutationKey: ['account', 'manual_verification'],
        mutationFn: (data) => axios.post('/api/account/manual_verification', data).then(res => res.data),
        onError: (e) => view.handleError(e)
    })

    const io = useSocket(process.env.REACT_APP_ADMIN_SERVER_URL)
    const accountId = context.user?.account_id || null

    const sizeError = att ? (att.size > 15 * 1024 * 1024) : false

    useEffect(() => {
        const EVENT = `business_verification_complete_${accountId}`

        const handleEvent = (res) => {
            context.signin(res);
        };

        io.on(EVENT, handleEvent)

        return () => {
            io.off(EVENT, handleEvent);
        };
    }, [io, accountId])

    return (
        <Animate type='pop'>
            <Row title='Verify Your Business'>
                <Card loading={loading} center className='max-w-xl'>
                    <div>
                        <TextInput
                            label='Business Name'
                            name='business_name'
                            type='text'
                            placeholder='Business Name'
                            value={data?.business_name}
                        />

                        <TextInput
                            label='Business Address'
                            name='business_address'
                            type='text'
                            placeholder='Business Address'
                            value={data?.business_address}
                        />

                        <PhoneInput
                            label='Business Phone Number'
                            name='business_phonenumber'
                            type='text'
                            placeholder='Business Phone Number'
                            value={data?.business_phonenumber}
                        />
                    </div>

                    <p className='my-5'>We will be contacting you through your provided business telephone number in
                        order to verify your business. Please ensure you are able to receive this call on your business
                        line, as it will contain a confirmation code necessary for the verification process.</p>

                    <div className='flex items-center flex-wrap gap-y-1 gap-x-4'>
                        <Button
                            text={calling ? seconds + 's' : 'Call Now'}
                            disabled={callMutation.isLoading || calling}
                            className='w-fit'
                            action={handleCall}
                            loading={callMutation.isLoading}
                            small
                        />
                        <span>{data?.business_phonenumber}</span>
                    </div>

                    <VerificationInput
                        ref={varificationInputRef}
                        length={6}
                        classNames={{
                            container: 'mt-4',
                            character: 'border border-blue-300 rounded-md',
                            characterSelected: 'border border-blue-300',
                            characterInactive: 'bg-blue-50',
                        }}
                        value={verificationCode}
                        onChange={handleVerificationCode}
                    />
                    {
                        verificationMutation.isLoading && (
                            <CircleSpinner width={16} height={16} />
                        )
                    }

                    <div className='mt-6'>
                        <p>Having trouble receiving your verification code?</p>
                        <button
                            className='text-blue-400 underline'
                            onClick={() => setModal(true)}
                        >
                            Click here to request manual verification
                        </button>
                        {
                            modal && (
                                <ModalContainer close={() => setModal(false)}>
                                    <Card title='Having trouble receiving your verification code?'>
                                        <>
                                            {
                                                manualVMutation.isSuccess ? (
                                                    <div>
                                                        <p>
                                                            Thank you! Your request has been sent to our support team. We
                                                            will reach out to you via email within 24 to 48 hours to
                                                            manually verify your account. Thank you for your patience.
                                                        </p>
                                                        <Button
                                                            className='!w-full'
                                                            text='Close'
                                                            color='green'
                                                            action={() => setModal(false)}
                                                        />
                                                    </div>
                                                ) : (
                                                    <form id='v_form'>
                                                        <p>If you're unable to receive your verification code due to an IVR
                                                            or any other issue, please fill out the form below, and we will
                                                            manually verify your account.</p>
                                                        <TextInput
                                                            name='desc'
                                                            type={'textarea'}
                                                            placeholder={"My IVR system doesn't allow me to hear the code."}
                                                        />
                                                        <div className='mb-6'>
                                                            <p className='text-sm'>
                                                                To help us expedite the verification process, you can also
                                                                upload a photo of your business card, front of your
                                                                business, letterhead, or any other information that can help
                                                                us verify your business.
                                                            </p>
                                                            <input
                                                                name='att'
                                                                ref={attRef}
                                                                type='file'
                                                                className='hidden'
                                                                accept=".jpeg, .jpg, .png, .heic, .gif, .bmp, .tiff, .tif, .webp, .pdf"
                                                                onChange={(e) => setAtt(e.target.files[0])}
                                                            />
                                                            <button
                                                                type='button'
                                                                onClick={() => attRef.current.click()}
                                                                className={`!w-full h-12 border border-dashed ${sizeError ? 'border-red-500 text-red-500' : 'border-blue-300'} text-blue-600 rounded text-center`}
                                                            >
                                                                {att ? att.name : 'Upload Attachment'}
                                                            </button>
                                                            {
                                                                sizeError && (
                                                                    <p className={'text-sm text-red-500'}>File size exceeds 5MB
                                                                        limit.</p>
                                                                )
                                                            }
                                                        </div>
                                                        <Button
                                                            className='!w-full'
                                                            type={'submit'}
                                                            text='Submit Request'
                                                            loading={manualVMutation.isLoading}
                                                            disabled={sizeError || manualVMutation.isLoading}
                                                            action={() => {
                                                                if (sizeError) return
                                                                const data = Object.fromEntries(new FormData(document.getElementById('v_form')))
                                                                const formData = new FormData()
                                                                formData.set('desc', data.desc)
                                                                formData.set('att', att)
                                                                manualVMutation.mutate(formData)
                                                            }}
                                                        />
                                                    </form>
                                                )
                                            }
                                        </>
                                    </Card>
                                </ModalContainer>
                            )
                        }
                    </div>

                </Card>
                <Button
                    text='Sign Out'
                    variant='text'
                    color='red'
                    action={context.signout}
                    className='mt-5'
                />
            </Row>

        </Animate>
    );
}
